const consts = {
  defaultIssuerId: "a2b88390-1ef5-4c87-87ab-8b0fbe130da7",
  defaultCurrencyId: "433e12b0-fc6e-4419-8903-9bff56d1a8e3",
  defaultCurrencySymbol: "GEL",
  issuerAddress: "ra9oZu6zB3TzSPfmJE4BakCWVJwsXtBJoQ",
  explorerTransactionsLink:
    "https://custom.xrpl.org/nbg.rppl-rpl1.projects.epam.com/transactions",
  explorerAccountsLink:
    "https://custom.xrpl.org/nbg.rppl-rpl1.projects.epam.com/accounts",
  cbdcWalletHost: "https://pilot.nbg.rppl-rpl1.projects.epam.com/wallet",
};

export default consts;
